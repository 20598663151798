import Api from './Api'

export default {
    getDistList() {
        return Api().get('/api/distributor.php?id=' + localStorage.getItem('userid'))
    },

    getSeatLayout(seatLayoutKey) {
        return Api().get('/api/getseatlayout.php?seatLayoutKey=' + seatLayoutKey)
    },

    // New Saif

    getCinemaFinance() {
        return Api().post('api/dashuser/GetCinemafinance/')
    }
}