<template>
<div className="animated">
    <b-card>
      <b-card-header>
        Cinema
        <div class="card-header-actions">
          
        </div>
      </b-card-header>
      <b-card-body>
        <v-client-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable">
          <div slot="Id" slot-scope="props">
            {{props.row.Id}}
          </div>
          <!-- <div slot="ChangeStatus" slot-scope="props">
            <c-switch class="mx-1" color="primary" v-model="props.row.ChangeStatus" defaultChecked variant="3d" label v-bind="labelIcon" @change.native="changeStatus()"/>
          </div> -->

          <!-- <div slot="OrganizationID" slot-scope="props">
            <i class="fa fa-edit" @click="editOrganization()"></i>
          </div> -->

          <div slot="ChangeStatus" slot-scope="props" :class="props.row.ChangeStatus=='Confirmed'? 'text-success': 'text-red'">
            {{ props.row.ChangeStatus }}
          </div>

          <div slot="seatLayoutKey" slot-scope="props">
            <i class="icon-eye" @click="viewSeatLayout(props.row.seatLayoutKey)"></i>            
          </div>
        </v-client-table>
      </b-card-body>
    </b-card>
  </div>
</template>


<script>
import Vue from 'vue'
import { Switch as cSwitch } from '@coreui/vue'
import {ClientTable, Event} from 'vue-tables-2'
import CinemaService from '@/services/CinemaService'
import CineAsiaDistributorService from '@/services/CineAsiaDistributorService'

// import OrganizationService from '@/services/OrganizationService'

Vue.use(ClientTable)
export default {
    name: 'Cinema',
    components: {
      ClientTable,
      Event,
      cSwitch
    }, 
    data() {
      return {
        columns: ['Id','Cinema', 'Location', 'Title', 'Capacity', 'Threshold', 'BiddingStatus', 'DateToGo', 'Date', 'Time', 'ChangeStatus', 'DCPStatus', 'KeyPlayStatus', 'seatLayoutKey'],
        data: [],
        options: {
          headings: {
            Id: 'Id',
            Cinema: 'Cinema',
            Location: 'Location',
            Title: 'Title',
            Capacity: 'Capacity',
            Threshold: 'Threshold',
            BiddingStatus: 'No. of Tickets booked',
            Date: 'Screening created for Date',
            Time: 'Screening Time',
            DateToGo: 'Days to go',
            ChangeStatus: 'Screening Status',
            DCPStatus: 'DCP Status',
            KeyPlayStatus: 'KeyPlayStatus',
            seatLayoutKey: 'View Seat Layout'
          },
          sortable: ['Cinema', 'Location', 'Date', 'Time', 'BiddingStatus'],
          filterable: ['Cinema', 'Location', 'Date', 'Time'],
          sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
          pagination: {
            chunk: 5,
            edge: false,
            nav: 'scroll'
          }
        },
        useVuex: false,
        theme: 'bootstrap4',
        template: 'default',
        labelIcon: {
          dataOn: '\u2713',
          dataOff: '\u2715'
        },
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      async getList() {
        let res = await CineAsiaDistributorService.getDistList();
        console.log('res :', res);
        this.data = res.data;
        this.data.map((d, index) => {
          d.Id = index+1
          d.Date = d.DateTime.split(' ')[0];
          d.Time = d.DateTime.split(' ')[1];
        })
      },
      viewSeatLayout(seatLayoutKey) {
        this.$router.push({path: '/view-seat-layout', query: {seatLayoutKey: seatLayoutKey}})
      }
    }
}
</script>


<style>
.text-red {
  color: #fa4502;
}

.card {
  border-radius: 18px;
}

thead {
  background-color: #2B2D42;
  white-space: nowrap;
  color: #fff;
}
thead th {
  position: relative;
  font-weight: normal !important;
}
.form-control {
  border-radius: 10px;
}

.fa-sort, .fa-sort-asc, .fa-sort-desc {
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid #ddd;
  color: #ddd;
  font-size: 8px;
  padding: 2px;
}
</style>